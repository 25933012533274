import axios from "axios";

let BaseApi = axios.create({
    baseURL:
        process.env.MIX_APP_MODE == "local"
            ? process.env.MIX_API_URL_DEV
            : process.env.MIX_API_URL
});

let Api = function() {
    let token = localStorage.getItem("admin-token");
    if (token) {
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseApi;
};

export default {
    api() {
        return Api();
    },

    login(form) {
        return Api().post("/admin/login", form);
    },

    logout() {
        return Api().post("/admin/logout");
    },

    changePassword(form) {
        return Api().post("/admin/change-password", form);
    },

    auth() {
        return Api().post("/admin/me");
    },

    allEmployee() {
        return Api().get("/employee/all");
    },

    createEmployee(form) {
        return Api().post("/employee", form);
    },

    updateEmployee(form, id) {
        return Api().put("/employee/" + id, form);
    },

    deleteEmployee(id) {
        return Api().delete("/employee/" + id);
    },

    createDocument(form) {
        return Api().post("/document", form);
    },

    updateDocument(form, id) {
        return Api().post("/document/" + id, form);
    },

    deleteDocument(id) {
        return Api().delete("/document/" + id);
    },

    createObjective(form) {
        return Api().post("/objective", form);
    },

    updateObjective(form, id) {
        return Api().post("/objective/" + id, form);
    },

    deleteObjective(id) {
        return Api().delete("/objective/" + id);
    }
};
